import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from 'redux';
import rootSaga from "./sagas";
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import crossBrowserListener from './shared/utils/reduxpersist-listener';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware
];
const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(...middlewares)
);
const persistor = persistStore(store)

window.addEventListener('storage', crossBrowserListener(store, persistConfig));
sagaMiddleware.run(rootSaga);

export {
    store,
    persistor
};
