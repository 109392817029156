import canvasActionTypes from './canvasActionTypes';

const initialState = {
    canvas: null,
    currentAction: null,
};

const canvasReducer = (state = initialState, action) => {
    switch (action.type) {
    case canvasActionTypes.SET_CANVAS:
        return Object.assign({}, state, {
            canvas: action.payload,
        });
    case canvasActionTypes.SET_CANVAS_ACTION:
        return Object.assign({}, state, {
            currentAction: action.payload,
        });
    case canvasActionTypes.RESET_CANVAS_ACTION:
        return Object.assign({}, state, {
            currentAction: null,
        });
    default:
        return state;
    }
};

export default canvasReducer;
