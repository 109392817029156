import React from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import filter from "lodash/filter";
import { Table, Button } from "antd";

import {
  setCanvas,
  setCanvasAction
} from "../../features/canvas/canvasActions";
import AddShape from "./AddShape";
import EditShape from "./EditShape";

const DataPage = ({ canvas, setCanvasAction }) => {
  const onDelete = (record) => {
    setCanvasAction({
      type: "delete",
      props: {
        id: record.id,
      }
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "X Coordinate",
      dataIndex: "left",
      key: "left"
    },
    {
      title: "Y Coordinate",
      dataIndex: "top",
      key: "top"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <EditShape setCanvasAction={setCanvasAction} record={record} />
          <Button
            onClick={() => onDelete(record)}
          >
            Delete
          </Button>
        </span>
      )
    }
  ];

  const data = map(canvas, obj => ({
    key: obj.id,
    id: obj.id,
    top: obj.top,
    left: obj.left
  }));

  const selectedKeys = map(
    filter(canvas, obj => obj.selected),
    obj => obj.id
  );
  const onSelectChange = selectedRowKeys => {
    setCanvasAction({
      type: "select",
      props: { selectedIds: selectedRowKeys }
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange
  };

  return (
    <React.Fragment>
      <AddShape setCanvasAction={setCanvasAction} />
      <Table rowSelection={rowSelection} dataSource={data} columns={columns} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  canvas: state.canvasReducer.canvas,
  canvasObj: state.canvasReducer.canvasObj,
  currentAction: state.canvasReducer.currentAction
});

const mapDispatchToProps = dispatch => ({
  setCanvas: params => dispatch(setCanvas(params)),
  setCanvasAction: params => dispatch(setCanvasAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataPage);
