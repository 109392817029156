import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fabric } from "fabric";
import find from "lodash/find";
import filter from "lodash/filter";
import map from "lodash/map";
import { Button, Tooltip } from "antd";
import {
  PlusOutlined,
  CopyOutlined,
  SelectOutlined,
  SnippetsOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  setCanvas,
  setCanvasAction,
  resetCanvasAction
} from "../../features/canvas/canvasActions";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clipboard: null,
      action: null
    };
  }

  componentDidMount() {
    this.canvas = new fabric.Canvas("main-canvas", {
      width: this.divElement.clientWidth,
      height: this.divElement.clientHeight,
      selection: false
    });

    const canvasJson = {
      objects: map(this.props.canvas, obj => ({
        ...obj,
        type: "circle",
        radius: 10,
        fill: "green",
        originX: "center",
        originY: "center",
        lockScalingX: true,
        lockScalingY: true
      })),
      background: ""
    };

    this.canvas.loadFromJSON(
      canvasJson,
      this.canvas.renderAll.bind(this.canvas),
      function(o, object) {}
    );

    var sel = new fabric.ActiveSelection(
      filter(this.canvas._objects, _obj => _obj.selected),
      {
        canvas: this.canvas,
        lockScalingX: true,
        lockScalingY: true
      }
    );
    this.canvas.setActiveObject(sel);

    // this.canvas.on("mouse:over", e => {
    //   if (e.target && !this.canvas.getActiveObjects().length) {
    //     var text = new fabric.Text(
    //       `(${e.target.left}, ${e.target.top}) WEST_01-01-01`,
    //       {
    //         id: `text_${e.target.id}`,
    //         top: e.target.top,
    //         left: e.target.left + 20,
    //         textBackgroundColor: "red",
    //         fontSize: 15,
    //         fill: "#FFFFFF",
    //         evented: false
    //       }
    //     );
    //     this.canvas.add(text);
    //     this.canvas.renderAll();
    //   }
    // });

    // this.canvas.on("mouse:out", e => {
    //   if (e.target) {
    //     this.canvas.remove(
    //       find(
    //         this.canvas.getObjects(),
    //         object => object.id === `text_${e.target.id}`
    //       )
    //     );
    //     this.canvas.renderAll();
    //   }
    // });

    this.canvas.on("mouse:down", options => {
      if (this.state.action === "draw") {
        this.props.setCanvasAction({
          type: "draw",
          props: {
            top: options.e.offsetY,
            left: options.e.offsetX
          }
        });
      }
    });

    this.canvas.on("selection:created", e => {
      e.target.lockScalingX = true;
      e.target.lockScalingY = true;
      this.updateCanvas();
    });

    this.canvas.on("selection:cleared", e => {
      this.updateCanvas();
    });

    this.canvas.on("object:moving", e => {
      var obj = e.target;
      // if object is too big ignore
      if (
        obj.currentHeight > obj.canvas.height ||
        obj.currentWidth > obj.canvas.width
      ) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height >
          obj.canvas.height ||
        obj.getBoundingRect().left + obj.getBoundingRect().width >
          obj.canvas.width
      ) {
        obj.top = Math.min(
          obj.top,
          obj.canvas.height -
            obj.getBoundingRect().height +
            obj.top -
            obj.getBoundingRect().top
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width -
            obj.getBoundingRect().width +
            obj.left -
            obj.getBoundingRect().left
        );
      }
      this.updateCanvas();
    });
  }

  componentDidUpdate() {
    if (this.props.currentAction) {
      if (this.props.currentAction.type === "draw") {
        this.draw(this.props.currentAction.props);
        this.props.resetCanvasAction();
      } else if (this.props.currentAction.type === "edit") {
        this.edit(this.props.currentAction.props);
        this.props.resetCanvasAction();
      } else if (this.props.currentAction.type === "delete") {
        this.delete(this.props.currentAction.props);
        this.props.resetCanvasAction();
      } else if (this.props.currentAction.type === "select") {
        this.selectObj(this.props.currentAction.props);
        this.props.resetCanvasAction();
      }
    }
  }

  draw = props => {
    var shape = new fabric.Circle({
      id: `${this.canvas._objects.length}`,
      top: props.top,
      left: props.left,
      originX: "center",
      originY: "center",
      radius: 10,
      lockScalingX: true,
      lockScalingY: true,
      fill: "green",
      onSelect: () => {
        this.setAction("select");
      }
    });
    this.canvas.add(shape);
    this.updateCanvas();
  };

  edit = props => {
    const obj = find(this.canvas._objects, _obj => _obj.id === props.id);
    obj.set("top", props.top).setCoords();
    obj.set("left", props.left).setCoords();
    this.canvas.requestRenderAll();
    this.updateCanvas();
  };

  delete = props => {
    const obj = find(this.canvas._objects, _obj => _obj.id === props.id);
    this.canvas.remove(obj);
    this.updateCanvas();
  };

  selectObj = props => {
    this.canvas.discardActiveObject();
    var sel = new fabric.ActiveSelection(
      filter(this.canvas.getObjects(), _obj =>
        props.selectedIds.includes(_obj.id)
      ),
      {
        canvas: this.canvas
      }
    );
    this.canvas.setActiveObject(sel);
    this.canvas.requestRenderAll();
    this.updateCanvas();
  };

  updateCanvas = () => {
    const canvasData = map(this.canvas.getObjects(), _obj => {
      const selected = map(
        this.canvas.getActiveObjects(),
        _obj => _obj.id
      ).includes(_obj.id);
      return {
        id: _obj.id,
        top: _obj.group
          ? Math.round(_obj.group.top + _obj.group.height / 2 + _obj.top)
          : Math.round(_obj.top),
        left: _obj.group
          ? Math.round(_obj.group.left + _obj.group.width / 2 + _obj.left)
          : Math.round(_obj.left),
        selected
      };
    });
    this.props.setCanvas(canvasData);
  };

  setAction = action => {
    this.setState({
      action
    });
    this.canvas.selection = action === "select";
  };

  copy = () => {
    if (this.canvas.getActiveObject()) {
      this.canvas.getActiveObject().clone(cloned => {
        this.setState({
          clipboard: cloned
        });
      });
    }
  };

  paste = () => {
    if (this.state.clipboard) {
      let clip = this.state.clipboard;
      clip.clone(clonedObj => {
        this.canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true
        });
        if (clonedObj.type === "activeSelection") {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = this.canvas;
          clonedObj.forEachObject(obj => {
            obj.id = this.canvas._objects.length;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            this.canvas.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          clonedObj.id = this.canvas._objects.length;
          clonedObj.lockScalingX = true;
          clonedObj.lockScalingY = true;
          this.canvas.add(clonedObj);
        }
        clip.top = clip.top + 10;
        clip.left = clip.left + 10;
        this.setState({
          clipboard: clip
        });
        this.canvas.setActiveObject(clonedObj);
        this.canvas.requestRenderAll();
      });
    }
  };

  clear = () => {
    this.canvas.clear();
    this.updateCanvas();
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Tooltip placement="right" title="Draw">
            <Button
              type={this.state.action === "draw" ? "primary" : ""}
              icon={<PlusOutlined />}
              onClick={() => this.setAction("draw")}
            />
          </Tooltip>
          <Tooltip placement="right" title="Select">
            <Button
              type={this.state.action === "select" ? "primary" : ""}
              icon={<SelectOutlined />}
              onClick={() => this.setAction("select")}
            />
          </Tooltip>
          <Tooltip placement="right" title="Copy">
            <Button icon={<CopyOutlined />} onClick={this.copy} />
          </Tooltip>
          <Tooltip placement="right" title="Paste">
            <Button icon={<SnippetsOutlined />} onClick={this.paste} />
          </Tooltip>
          <Tooltip placement="right" title="Clear">
            <Button icon={<DeleteOutlined />} onClick={this.clear} />
          </Tooltip>
        </div>
        <div
          ref={divElement => {
            this.divElement = divElement;
          }}
          style={{ width: "100%", height: "100vh" }}
        >
          <canvas id="main-canvas" style={{ border: "1px solid black" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  canvas: state.canvasReducer.canvas,
  currentAction: state.canvasReducer.currentAction
});

const mapDispatchToProps = dispatch => ({
  setCanvas: params => dispatch(setCanvas(params)),
  setCanvasAction: params => dispatch(setCanvasAction(params)),
  resetCanvasAction: () => dispatch(resetCanvasAction())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
