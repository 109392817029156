import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Canvas from "./pages/canvas";
import Data from "./pages/data";

const MainApp = () => {
  let match = useRouteMatch();

  return (
      <Switch>
        <Route path={`${match.path}/canvas`} component={Canvas} />
        <Route path={`${match.path}/data`} component={Data} />
      </Switch>
  );
};

export default MainApp;
