import React, { useState } from "react";
import { Button, Modal, InputNumber } from "antd";

const EditShape = ({ record, setCanvasAction }) => {
  const [visible, setVisibility] = useState(false);
  const [x, setX] = useState(record.left);
  const [y, setY] = useState(record.top);
  const handleOk = () => {
    setCanvasAction({
      type: "edit",
      props: {
        id: record.id,
        top: y,
        left: x
      }
    });
    setVisibility(false);
  };
  const handleCancel = () => {
    setVisibility(false);
  };
  return (
    <React.Fragment>
      <Button onClick={() => setVisibility(true)}>Edit Shape</Button>
      <Modal
        title="Edit Shape"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <InputNumber
          min={0}
          max={1000}
          onChange={value => setX(value)}
          placeholder="X"
          value={x}
        />
        <InputNumber
          min={0}
          max={1000}
          onChange={value => setY(value)}
          placeholder="Y"
          value={y}
        />
      </Modal>
    </React.Fragment>
  );
};

export default EditShape;
