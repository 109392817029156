import canvasActionTypes from './canvasActionTypes';

const setCanvas = (payload) => {
    return {
        type: canvasActionTypes.SET_CANVAS,
        payload,
    };
};

const setCanvasAction = (payload) => {
    return {
        type: canvasActionTypes.SET_CANVAS_ACTION,
        payload,
    };
};

const resetCanvasAction = () => {
    return {
        type: canvasActionTypes.RESET_CANVAS_ACTION,
    };
};

export {
    setCanvas,
    setCanvasAction,
    resetCanvasAction,
};
